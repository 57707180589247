<template>
  <tr v-if="rowData.displayYn">
    <td>{{ index + 1 }}</td>
    <td>{{ rowData.accountName }}</td>
    <td>{{ rowData.taxName }}</td>
    <td>{{ rowData.deptName }}</td>
    <td>{{ rowData.barcode }}</td>
    <td>{{ rowData.goodsName }}</td>
    <td>{{ rowData.serviceCode }}</td>
    <td>{{ newUsedName }}</td>
    <td>{{ rowData.amount | currency }}</td>
    <td>{{ rowData.debit | currency }}</td>
  </tr>
</template>

<script>
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";

export default {
  name: "InfraVoucherListLine",
  components: { CommInpSearch },
  mixins: [CommFlatpickrMixin],
  props: {
    rowData: Object,
    index: Number,
  },
  computed: {
    // isHideVoucherItem() {
    //   return (this.rowData.accountCode === '1113000000') || (this.rowData.accountCode === '2101020000');
    // }
    newUsed() {
      return this.rowData.newUsed == "NEW" ? "신규" : "중고";
    },
  },
  computed: {
    newUsedName() {
      if (this.rowData.newUsed === "") return "";
      return this.rowData.newUsed === "NEW" ? "신규" : "중고";
    },
  },
  methods: {
    onFocusout(e) {
      e.target.classList.remove("error");
    },
  },
};
</script>
